.control {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    display: flex;
    flex-direction: column;
    & button {
        padding: 4px 16px;
        font-size: 16px;
        margin: 8px;
    }
}

@media(max-width: 1000px) {
    .control {
        & button {
            font-size: 12px;
            margin: 4px;
        }
    }
}