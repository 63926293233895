.master-settings {
    position: fixed;
    top: 8px;
    left: 16px;
    display: flex;
    flex-direction: column;


    & h5 {
        margin-top: 4px;
        color: white;
        font-weight: 600;
    }
}
