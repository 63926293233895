.App {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2e1f3e;
    display: flex;
    align-items: center;
    justify-content: center;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

input {
    height: 24px;
}

.fullscreen {
    position: fixed;
    top: 3px;
    right: 3px;
    background-color: white;
    border-radius: 50%;
    padding: 4px;
}

.background {
    /* The image used */
    background-image: url('./assets/img/background.webp');

    /* Add the blur effect */
    filter: blur(4px);
    -webkit-filter: blur(4px);

    /* Full height */
    height: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/* CSS */
button,
select {
    align-items: center;
    appearance: none;
    background-color: #fcfcfd;
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
        #d6d6e7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #36395a;
    cursor: pointer;
    display: inline-flex;
    font-family: 'JetBrains Mono', monospace;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow 0.15s, transform 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    padding: 4px 8px;

    &:focus {
        box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
            rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    }

    &:hover {
        box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
            #d6d6e7 0 -3px 0 inset;
        transform: translateY(-2px);
    }

    &:active {
        box-shadow: #d6d6e7 0 3px 7px inset;
        transform: translateY(2px);
    }
}
