.cards {
    position: absolute;
    & img {
        width: 32px;
        margin: 2px;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
    }
    z-index: 1;
    & span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: black;
        background-color: white;
        border: 0.5px solid #000;
        font-size: 14px;
        padding: 0px 2px;
        border-radius: 2px;
    }
}

@media (max-width: 1000px) {
    .cards {
        & img {
            width: 20px;
            margin: 2px;
        }

        & span {
            font-size: 12px;
        }
    }
}