.joinbox {
    width: 140px;
    height: 64px;
    padding: 4px;
    position: absolute;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    & div {
        background-color: #230342;
        padding: 4px;
        border: 2px solid #9880E9;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
        &:hover {
            transform: scale(1.2)
        }
    }

    &:hover {
        cursor: pointer;
    }
}

@media (max-width: 1000px) {
    .joinbox {
        width: 80px;
        height: 44px;

        & div {
            width: 28px;
            height: 28px;
        }
    }

}