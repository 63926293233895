// .player {
//     width: 140px;
//     height: 64px;
//     padding: 4px;
//     position: absolute;
//     color: white;
//     border-radius: 20px;
//     &-header {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: space-around;

//         &-info {
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             width: 100%
//         }

//         &-avatar {
//             width: 28px;
//             height: 28px;
//             border-radius: 50%;
//             border: 0.2px solid #fff;
//             overflow: hidden;
//             margin-right: 4px;
//             & img {
//                 width: 100%;
//                 height: 100%;
//                 object-fit: cover;
//             }
//         }

//         &-name {
//             font-size: 13px;
//         }

//         &-cash {
//             font-size: 13px;
//             &:hover {
//                 cursor: pointer;
//                 color: red;
//             }
//         }
//     }

//     &-master {
//         position: absolute;
//         top: -14px;
//         right: -8px;
//         font-size: 20px;
//         z-index: 4;
//     }

//     &-emoji {
//         position: absolute;
//         top: -14px;
//         left: -8px;
//         font-size: 20px;
//         z-index: 4;
//     }
// }

// @media (max-width: 1000px) {
//     .player {
//         width: 80px;
//         height: 44px;
//         padding: 2px;
//         &-header {
//             &-name {
//                 font-size: 8px;
//             }
//             &-cash {
//                 font-size: 8px;
//             }
//             &-avatar {
//                 width: 16px;
//                 height: 16px;
//             }
//         }

//         &-master {
//             font-size: 12px;
//             top: -8px;
//             right: -8px;
//         }

//         &-emoji {
//             font-size: 12px;
//             top: -8px;
//             left: -8px;
//         }
//     }

// }

.master {
    .player {
        &-info {
            &-avatar {
                border: 2.5px solid #F70000;
            }
    
            & h5 {
                // background-color: #F70000;
                background-image: linear-gradient( 135deg, #F05F57 10%, #360940 100%);
            }
        }

        // &-put p {
        //     background-color: #F70000;
        // }
    }
}

.you {
    .player {
        &-info {
            &-avatar {
                border: 2.5px solid #0d5b52;
            }
    
            & h5 {
                // background-color: #0d5b52;
                background-image: linear-gradient(135deg, #00a500 10%, #134007 100%);
            }
        }

        // &-put p {
        //     background-color: #0d5b52;
        // }
    }
}

.player {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 64px;
    &-info {
        position: relative;
        margin-bottom: 4px;
        &-avatar {
            width: 72px;
            height: 72px;
            border-radius: 50%;
            border: 2.5px solid #583eb0;
            box-shadow: 0 1px 6px rgb(0 0 0 / 12%), 0 1px 4px rgb(0 0 0 / 24%);
            overflow: hidden;
            margin-right: 4px;
            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        & h5 {
            position: absolute;
            bottom: -2px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 12px;
            color: white;
            // background-color: #583eb0;
            background-image: linear-gradient( 135deg, #3B2667 10%, #BC78EC 100%);
            border-radius: 4px;
            min-width: 60px;
            max-width: 80px;
            text-align: center;
            font-weight: 500;
            overflow: hidden;
            text-overflow: ellipsis; 
            white-space: nowrap; 
            box-shadow: 0 1px 6px rgb(0 0 0 / 12%), 0 1px 4px rgb(0 0 0 / 24%)
        }
    }

    &-put  {
        & p {
            color: #000;
            // background-color: #583eb0;
            background-image: linear-gradient( 135deg, #FDEB71 10%, #D0CF0C 100%);
            font-size: 12px;
            padding: 1px 4px;
            // border: 1.5px solid #583eb0;
            border-radius: 4px;
            font-weight: 520;
            display: flex;
            align-items: center;

            & img {
                width: 14px;
                margin-right: 2px;
            }
        }

        &:hover {
            transform: scale(1.1);
            cursor: pointer;
        }
    }

    &-cash {
        p {
            color: #fff;
            font-size: 14px;
            // text-shadow: 0px 0.5px #000;
            text-shadow: 1px 0 0 #9880E9, -1px 0 0 #9880E9, 0 1px 0 #9880E9, 0 -1px 0 #9880E9, 1px 1px #9880E9, -1px -1px 0 #9880E9, 1px -1px 0 #9880E9, -1px 1px 0 #9880E9;
            font-weight: 550;
        }
        &:hover {
            cursor: pointer;
        }
    }

    &-master {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 16px;
    }

    &-earn {
        position: absolute;
        left: 50%;
        // top: 50%;
        transform: translateX(-50%);
        color: rgb(172, 172, 13);
        font-size: 16px;
        font-weight: 600;
        text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
        animation: cashappear 0.2s;
        bottom: 40%;
    }

}

@keyframes cashappear {
    0% {bottom: 12%}
    25%  {bottom: 18%}
    50%  {bottom: 24%}
    75%  {bottom: 32%}
    100% {bottom: 40%}
}

@media (max-width: 1000px) {
    .player {
        width: 80px;
        height: 44px;

        &-info {

            &-avatar {
                width: 40px;
                height: 40px;
                border: 2px solid #583eb0;
            }

            & h5 {
                min-width: 40px;
                max-width: 60px;
                font-size: 8px;
                bottom: -2px;
            }
        }

        &-cash {

            p {
                font-size: 10px;
            }
        }

        &-put  {
            // margin-top: 4px;
            & p {
                font-size: 8px;
            }
        }

        &-master {
            position: absolute;
            top: 0px;
            right: 0px;
            font-size: 12px;
        }

        &-earn {
            font-size: 10px;
        }
    }
}