.cashinput {
    display: flex;
    flex-direction: column;
    width: 200px;
    position: fixed;
    // bottom: 8px;
    // left: 16px;
    // top: 50%;
    // left: 50%;
    // transform: translate(X);
    & div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        & select {
            width: 100%;
            // height: 32px;
            border-radius: 8px;
            background-color: white;
            padding: 8px;
        }
    }
    & button {
        border-radius: 8px!important;
        padding: 4px;
        margin-bottom: 8px;
    }
}

@media (max-width: 1000px) {
    .cashinput {
        width: 140px;
        button {
            font-size: 8px;
            padding: 4px;
            margin-bottom: 8px;
        }

        & div {
            & select {
                padding: 4px;
                font-size: 8px;
            }
            margin-bottom: 8px;
        }
        
    }
}