.sendcash {
    & form {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px;
        background-color: white;
        border: 1px solid #000;
        border-radius: 8px;
        z-index: 11;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        & input {
            width: 280px;
            margin: 4px;
            border: 0.5px solid #ccc;
            padding: 8px;
            background-color: white;
        }
    }

    & button {
        margin: 0px 4px;
    }
}