.board {
    width: 900px;
    height: 450px;
    background-color: #22AA9A;
    border-radius: 260px;
    border: 12px solid #b1dcd7;
    position: relative;
    // position: fixed;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);

    &-icon {
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translateX(-50%);
        width: 56px;
    }

    &-girl {
        position: absolute;
        top: -100px;
        left: 50%;
        transform: translateX(-50%);
        width: 80px; 
    }
}

@media (max-width: 1000px) {
    .board {
        width: 500px;
        height: 250px;
        border: 12px solid #b1dcd7;
        border-radius: 112px;

        & > img {
            width: 40px;
        }
    }
}