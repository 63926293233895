.contribute {
    position: absolute;
    bottom: 4px;
    left: 8px;
    display: flex;
    align-items: center;
    & p {
        color: white;
        font-size: 14px;
        margin-right: 4px;
        font-weight: 500;
    }
    & > div {
        display: flex;
    }
    &-avatar {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        overflow: hidden;
        margin: 2px;
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    bottom: 16px;
    font-size: 12px;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}
